import { Injectable } from '@angular/core';

import dayjs from 'dayjs';
import { Observable, from } from 'rxjs';
import { datadogLogs } from '@datadog/browser-logs';

import { AppInfoService } from '../../app/app-info-service';
import { DateHelper } from '../../helpers/date-helper';
import { AisActivity, RecursivePartial } from '../../helpers/types';
import { DprLoggingService } from '../../live-dpr/services/dpr-logging.service';
import { ActivityExtendedState, ActivityTabCode, ReportMetadata, ReportState, ReportUploadQuery, ReportUploadResult,
  ReportingRefData, ReportingVessel } from '../../live-dpr/models/reporting-types';
import { DialogManager } from '../dialog-manager';
import { SelectedVesselConfig } from '../../live-dpr/models/reporting-config-types';
import { PersistentDbConfig, ReportingDatabaseSchema, ReportingTableNameEnum } from './persistent-db.types';
import { PersistentDb } from './persistent-db';

export type ReportCompositeKey = { date: number; datetime: number; reportTypeId: number };

export type GetReportDetailsArgs = {
  reportIds: number[];
  vesselId?: undefined;
  reportCompositeKeys?: undefined;
} | {
  reportIds?: undefined;
  reportCompositeKeys: ReportCompositeKey[];
  vesselId: number;
} | {
  reportIds: number[];
  reportCompositeKeys: ReportCompositeKey[];
  vesselId: number;
};

export interface OfflineReportWithMetadata extends ReportMetadata {
  report?: ReportState;
  reportDetailsLastUpdate: number;
  lastOfflineModifiedTime?: number;
}

/**
 * The ref data is shared between all vessel, so we should have only one for all vessel in this table.
 * So we generate one unique key to get/update ref data
 */
const REF_DATA_ID = 'REF_DATA_KEY';
const SYNC_IF_SAVED_FOR_MORE_THAN = 60 * 1_000; // In ms

@Injectable({
  providedIn: 'root',
})
/**
 * ReportingOfflineDatabaseService is responsible for handling all the crud operations when our app is offline. When we are
 * online
 * we need it to insert data that will be loaded while offline
 *
 * In this class we use several interfaces
 *  -> Dpr - correspond to overview report information such as id, version, statusId, vessel, ... Mainly used for
 *  dpr/selected-vessel page / component
 *  -> DprReport - extends previous Dpr object, we also add `ReportState report` corresponding to reports detail, `boolean
 *  created offline`, `boolean modified offline`
 *  -> ReportState - DprReport.report reports detail, corresponding to dpr/selected-report page, for instance difference data
 *  that you can see inside each tabs
 */
export class ReportingOfflineDatabaseService {
  private static readonly persistentDbConfig: PersistentDbConfig<ReportingDatabaseSchema> = {
    databaseVersion: {
      major: 2,
      minor: 6,
    },
    databaseName: '', // We keep it empty to keep the same db name pattern as before and be able to clear previous ones.
    databaseSchema: {
      dpr_vessel: '++vesselId',
      dpr_report: '++id, vesselId, documentTypeId, dateOrDatetime, '
        + '[vesselId+documentTypeId+dateOrDatetime], reportDetailsLastUpdate',
      dpr_ref_data: 'id',
      dpr_autofill_ais_activities: '&[vesselId+reportDate], aisActivitiesGenerationDate',
      selected_vessel_config: '++vesselId',
    },
  };
  // Number of report details we want to store in local db
  private static readonly MAX_REPORT_DETAILS_NUMBER = 30;
  private static readonly MAX_AIS_ACTIVITIES_DATASET_NUMBER = 30;

  private persistentDb: PersistentDb<ReportingDatabaseSchema>;

  constructor(
    private readonly appInfoService: AppInfoService,
    private readonly dprLogging: DprLoggingService,
    private readonly dialogManager: DialogManager,
  ) {}

  public async setupDb(userEmail: string): Promise<void> {
    this.persistentDb = new PersistentDb(ReportingOfflineDatabaseService.persistentDbConfig, userEmail);
    await this.persistentDb.setup();
  }

  public async resetDb(): Promise<void> {
    await this.persistentDb.clearAllTables();
  }

  public async logDbSnapshot(): Promise<void> {
    const dbSnapshot = await this.persistentDb.queryAllTables();
    datadogLogs.logger.info(
      'Snapshot of user local db',
      {
        localDbSnapshot: dbSnapshot,
      },
    );
  }

  public getVessels(): Observable<ReportingVessel[]> {
    return from(this.persistentDb.queryAll<ReportingVessel>(ReportingTableNameEnum.DPR_VESSEL));
  }

  /**
   * @param vessels ReportingVessel[] - list of vessels to add to indexedDb
   */
  public async bulkPutVessels(vessels: ReportingVessel[]): Promise<void> {
    await this.persistentDb.bulkPut(ReportingTableNameEnum.DPR_VESSEL, vessels);
  }

  /**
   * @param vesselId number
   * @returns Promise<Dpr[]> - list of overview report information
   */
  public async getReportsMetadataByVesselId(vesselId: number): Promise<ReportMetadata[]> {
    const reports = await this.persistentDb.queryAll<OfflineReportWithMetadata>(
      ReportingTableNameEnum.DPR_REPORT,
      table =>
        table.where({ vesselId: +vesselId })
          .and((report: OfflineReportWithMetadata) =>
            this.appInfoService.config.selectedOsvProjectIds.includes(report.projectId)
          ),
    );

    return reports.map((report: OfflineReportWithMetadata) => {
      report.isLoadedOffline = this.isReportLoadedOffline(report);
      // Delete extra properties to avoid polluting the report cache with it.
      delete report.report;
      delete report.reportDetailsLastUpdate;
      delete report.lastOfflineModifiedTime;
      return report as ReportMetadata;
    });
  }

  private isReportLoadedOffline(report: OfflineReportWithMetadata): boolean {
    return !!report.vesselId && !!report.report && Object.keys(report.report).length > 0;
  }

  /**
   * Get reports from the local database by ids or dates.
   *
   * @param reportIds - report ids to fetch. Optional.
   * @param reportCompositeKeys - report {date, datetime, reportTypeId} to fetch. Required if vesselId is passed.
   * @param vesselId - vessel id for which to load reports by dates. Required if reportDates is passed.
   * @returns Promise<ReportState> - list of found reports.
   */
  public async getReportDetails(
    { reportIds, reportCompositeKeys, vesselId }: GetReportDetailsArgs,
  ): Promise<ReportState[]> {
    const queryingByReportIds = reportIds !== undefined && reportIds.length > 0;
    const queryingByDates = vesselId != null && reportCompositeKeys != null && reportCompositeKeys.length > 0;
    const reports: ReportState[] = [];

    if (queryingByReportIds) {
      const reportsByIds = await this.persistentDb.queryAll<OfflineReportWithMetadata>(
        ReportingTableNameEnum.DPR_REPORT,
        table =>
          table.where('id')
            .anyOf(reportIds)
            .and((report: OfflineReportWithMetadata) => this.isReportLoadedOffline(report)),
      );
      reports.push(...reportsByIds.map(report => report.report));
    }

    if (queryingByDates) {
      // IndexedDB cannot query against null, hence the query on dateOrDatetime
      const reportIdentifiers = reportCompositeKeys.map(
        rep => [vesselId, rep.reportTypeId, rep.date ?? rep.datetime],
      );
      const reportsByDates = await this.persistentDb.queryAll<OfflineReportWithMetadata>(
        ReportingTableNameEnum.DPR_REPORT,
        table =>
          table.where(['vesselId', 'documentTypeId', 'dateOrDatetime'])
            .anyOf(reportIdentifiers)
            .and((report: OfflineReportWithMetadata) => this.isReportLoadedOffline(report))
            .and((report: OfflineReportWithMetadata) =>
              this.appInfoService.config.selectedOsvProjectIds.includes(report.projectId)
            ),
      );
      reports.push(...reportsByDates.map(report => report.report));
    }

    return this.removeDuplicatedReport(reports);
  }

  private removeDuplicatedReport(reports: ReportState[]): ReportState[] {
    const uniqueReports: ReportState[] = [];
    const seenReportIds = new Set<string>();

    for (const report of reports) {
      const reportId = `${report.date}--${report.datetime}--${report.vesselId}--${report.documentTypeId}`;
      if (seenReportIds.has(reportId)) {
        continue;
      }

      seenReportIds.add(reportId);
      uniqueReports.push(report);
    }

    return uniqueReports;
  }

  /**
   * @returns boolean - whether the offline database contains report created offline or modified offline.
   */
  public async hasReportsUpdatedOffline(): Promise<boolean> {
    const reports = await this.persistentDb.queryAll<OfflineReportWithMetadata>(ReportingTableNameEnum.DPR_REPORT)
      .then(dprReports => dprReports.filter(report => report.isCreatedOffline || report.isModifiedOffline));
    return reports.length > 0;
  }

  /**
   * @returns OfflineReportWithMetadata[] - sorted list, by createdOffline, DprReport
   */
  private async getReports(reportTypeId?: number): Promise<OfflineReportWithMetadata[]> {
    let reports: OfflineReportWithMetadata[];
    if (reportTypeId == null) {
      reports = await this.persistentDb.queryAll<OfflineReportWithMetadata>(ReportingTableNameEnum.DPR_REPORT);
    } else {
      reports = await this.persistentDb.queryAll<OfflineReportWithMetadata>(
        ReportingTableNameEnum.DPR_REPORT,
        table => table.where({ documentTypeId: reportTypeId }),
      );
    }

    return this.sortReportsByCreatedOffline(reports);
  }

  private sortReportsByCreatedOffline(reports: OfflineReportWithMetadata[]): OfflineReportWithMetadata[] {
    return reports.sort((a, b) => +b.isCreatedOffline - +a.isCreatedOffline);
  }

  /**
   * Get reports to sync for current vessel. If a report was saved offline too recently, we won't sync it.
   *
   * @param vesselId number - the vessel for which to get the reports.
   * @returns DprReport[] - sorted list, by createdOffline, DprReport
   */
  public async getReportsToSync(vesselId: number | null): Promise<OfflineReportWithMetadata[]> {
    const reports = await this.persistentDb.queryAll<OfflineReportWithMetadata>(
      ReportingTableNameEnum.DPR_REPORT,
      table =>
        table.where({ vesselId: +vesselId })
          .and((report: OfflineReportWithMetadata) => {
            const hasReportData = report && report.report && Object.keys(report?.report).length > 0;
            const mustBeUpdatedFromServer = !report.lastOfflineModifiedTime
              || (Date.now() - report.lastOfflineModifiedTime) > SYNC_IF_SAVED_FOR_MORE_THAN;
            const mustBeUploadedToServer = report.isCreatedOffline
              || report.isModifiedOffline;
            const mustBeSynced = mustBeUpdatedFromServer || mustBeUploadedToServer;
            const isInSelectedProjects = this.appInfoService.config.selectedOsvProjectIds.includes(report.projectId);

            return hasReportData && mustBeSynced && isInSelectedProjects;
          }),
    );

    return this.sortReportsByCreatedOffline(reports);
  }

  /**
   * @param vesselId
   * @param reportTypeId filter out activities in report not of same type
   * @param maxDate filter out activities before maxDate
   * @returns ActivityExtendedState[] - list of activities sorted by decreasing dateEnd
   */
  public async getMainActivitiesFromVessel(
    vesselId: number,
    reportTypeId: number,
    maxDate: dayjs.Dayjs | null = null,
  ): Promise<ActivityExtendedState[]> {
    const reports = await this.getReports(reportTypeId);
    let activities: ActivityExtendedState[] = [];
    for (const rep of reports) {
      if (rep.report?.activities && rep.vesselId === vesselId) {
        activities = activities.concat(...rep.report.activities).filter(ac => ac.activityTab === ActivityTabCode.MAIN);
      }
    }
    activities = activities
      .filter(act => maxDate === null ? true : dayjs.utc(act.start).isBefore(maxDate))
      .sort((a, b) => dayjs.utc(b.end).diff(dayjs.utc(a.end)));

    return activities;
  }

  /**
   * @description Returns all activities that have been interrupted and could be continued by the user before the
   * current report date.
   * An activity that can be continued is defined as follows:
   *   - It must miss an end
   *   - It must be the first one of its activity type in the chronological order (by desc) starting from current report
   *   date
   *
   * Ex.:
   *     Act A1 type A interrupted day 1
   *     Act A2 type A interrupted day 2
   *     Act B1 type B interrupted day 1
   *     Act B2 type B not interrupted
   *   Should return A2 if current report is day 2, but A1 and B1 if current report is day 1
   * @param vesselId Vessel ID to search for ongoing activities
   * @param reportEndDatetime End datetime of the report where the user wants to add an activity - we don't want to
   *                          propose the user to continue an activity in the future
   * @returns ActivityExtendedState[] - list of to-be-continued activities for this vessel sorted by increasing dateEnd
   */
  public async getOngoingActivities(
    vesselId: number,
    reportTypeId: number,
    reportEndDatetime: dayjs.Dayjs,
  ): Promise<ActivityExtendedState[]> {
    let activities = await this.getMainActivitiesFromVessel(vesselId, reportTypeId);
    activities = activities.filter(act => dayjs.utc(act.start) < reportEndDatetime);

    const ongoingActivities: ActivityExtendedState[] = [];
    const finishedActivityTypeIds: number[] = [];
    for (const act of activities) {
      // Simple and InterruptedEnd activities mean that there is no activity of this type we could continue
      if (!act.interruptMissingEnd) {
        finishedActivityTypeIds.push(act.activityTypeId);
      } else if (act.interruptMissingEnd && !finishedActivityTypeIds.includes(act.activityTypeId)) {
        ongoingActivities.push(act);
        finishedActivityTypeIds.push(act.activityTypeId);
      }
    }
    return ongoingActivities;
  }

  /**
   * @description add / update report detail, corresponding to report.report data
   * @param modifiedReport ReportState
   * @param isModifiedOffline boolean
   * @returns number - return modified report id
   */
  private async putReportDetails(
    modifiedReport: ReportState,
    isModifiedOffline: boolean = false,
    newReport: boolean = false,
  ): Promise<OfflineReportWithMetadata> {
    const report = await this.getCurrentReportDetailsOrStub(modifiedReport.dailyReportId);

    const updatedReport: OfflineReportWithMetadata = {
      id: report.id,
      title: modifiedReport.title || report.title,
      date: modifiedReport.date ? DateHelper.isoInTimestamp(modifiedReport.date) : report.date ?? null,
      datetime: modifiedReport.datetime ? DateHelper.isoInTimestamp(modifiedReport.datetime) : report.datetime ?? null,
      dateOrDatetime: DateHelper.isoInTimestamp(modifiedReport.dateOrDatetime) || report.dateOrDatetime,
      projectId: modifiedReport.projectId || report.projectId,
      project: modifiedReport.project || report.project,
      lastUpload: DateHelper.isoInTimestamp(modifiedReport.lastUpload),
      lastUpdate: DateHelper.isoInTimestamp(modifiedReport.lastUpdate),
      statusId: modifiedReport.statusId,
      vesselId: modifiedReport.vesselId,
      version: modifiedReport.version,
      approvers: modifiedReport.approvers,
      documentTypeId: modifiedReport.documentTypeId,
      isDaily: modifiedReport.isDaily,
      modifiedByFullName: modifiedReport.modifiedBy,
      report: {
        ...report.report,
        ...modifiedReport,
      },
      isCreatedOffline: newReport || report.isCreatedOffline,
      isModifiedOffline,
      reportDetailsLastUpdate: dayjs.utc().valueOf(),
      lastOfflineModifiedTime: Date.now(),
    };

    /**
     * If new report doesn't match basic condition we just return the function
     * The last valid version of the report will be kept.
     */
    if (!this.dprReportBasicCheck(updatedReport)) {
      return report as OfflineReportWithMetadata;
    }

    const dexieId = await this.persistentDb.put(ReportingTableNameEnum.DPR_REPORT, updatedReport);
    // Offline creation: report id is created now hence the need to update `report.report.dailyReportId`
    if (newReport) {
      updatedReport.report.dailyReportId = +dexieId;
      await this.persistentDb.put(ReportingTableNameEnum.DPR_REPORT, updatedReport);
    }

    await this.cleanUpDb();
    return updatedReport;
  }

  private async getCurrentReportDetailsOrStub(
    reportId: number | undefined,
  ): Promise<RecursivePartial<OfflineReportWithMetadata>> {
    let report: RecursivePartial<OfflineReportWithMetadata> = {} as RecursivePartial<OfflineReportWithMetadata>;

    if (reportId) {
      report = await this.persistentDb.queryFirst<RecursivePartial<OfflineReportWithMetadata>>(
        ReportingTableNameEnum.DPR_REPORT,
        table => table.where({ id: reportId }),
      );
    }

    /*
     * First insertion in offline db, report id is only known by modifiedReport hence the need to add it to the report
     * object;
     */
    if (!report && reportId) {
      report = { 'id': reportId };
    } else if (!report) {
      report = {};
    }

    if (!report.report) {
      report['report'] = {} as Partial<ReportState>;
    }

    return report;
  }

  /**
   * Called after put a report detail in db, we iterate on the report data stored in db
   * and we only keep the details of the last MAX_REPORT_DETAILS_NUMBER reports added.
   * The report details take a lot of space in size.
   * (cf. https://github.com/spinergie/spinapp/pull/3529#issue-1659024653)
   * Keeping the placeholders of the reports but removing the details allows us to greatly optimize
   * the size of the db local in the long run
   *
   * We also clean up the ais activities and keep those generated for the last 30 visited reports
   */
  public async cleanUpDb(): Promise<void> {
    // Get all report with details
    const reportsWithDetails = await this.persistentDb.query<OfflineReportWithMetadata[]>(
      ReportingTableNameEnum.DPR_REPORT,
      table =>
        table.where('reportDetailsLastUpdate')
          .aboveOrEqual(0)
          .sortBy('reportDetailsLastUpdate'),
    );

    if (reportsWithDetails.length > ReportingOfflineDatabaseService.MAX_REPORT_DETAILS_NUMBER) {
      // Remove details of reports above the limit to reduce size impact of offline DB
      const reportsToClean = reportsWithDetails.reverse().slice(
        ReportingOfflineDatabaseService.MAX_REPORT_DETAILS_NUMBER,
      ).map(
        reportToClean => {
          reportToClean.report = {} as ReportState;
          delete reportToClean.reportDetailsLastUpdate;
          return reportToClean;
        },
      );
      await this.persistentDb.bulkPut(ReportingTableNameEnum.DPR_REPORT, reportsToClean);
    }
    /**
     * Clean up all aisActivities for report above the limit
     * (meaning for MAX_AIS_ACTIVITIES_DATASET_NUMBER reports ais activities has been loaded in local db
     * since we loaded ais activities for these reports)
     */
    await this.persistentDb.query(
      ReportingTableNameEnum.DPR_AUTOFILL_AIS_ACTIVITIES,
      table =>
        table.orderBy('aisActivitiesGenerationDate')
          .reverse()
          .offset(ReportingOfflineDatabaseService.MAX_AIS_ACTIVITIES_DATASET_NUMBER)
          .delete(),
    );
  }

  /**
   * Checks that the report meets the basic requirements before being inserted in local db
   *
   * @param report Report to be inserted in db local
   * @returns boolean true if report is valid
   */
  private dprReportBasicCheck(report: ReportMetadata): boolean {
    const invalidFields: string[] = [];
    if (!report.dateOrDatetime || report.dateOrDatetime < 0) {
      invalidFields.push('datetime');
    }
    if (!report.projectId || report.projectId <= 0) {
      invalidFields.push('projectId');
    }
    if (!report.vesselId || report.vesselId <= 0) {
      invalidFields.push('vesselId');
    }
    if (invalidFields.length) {
      this.dialogManager.showMessage(
        `Error while updating report: ${report.id}.
			The last valid version of the report will be kept. Please reload the page.`,
        'error',
      );
      this.dprLogging.logInvalidLocalReportInsert(report, invalidFields);
      return false;
    }
    return true;
  }

  /**
   * @description add / update Report in indexedDb
   * @param newReport Report
   */
  private async putReportMetadata(newReport: ReportMetadata): Promise<void> {
    if (!newReport.id) {
      return;
    }

    if (newReport.deleted || newReport.clientDiscard || newReport.isAnonymized) {
      this.deleteReport(newReport.id);
      return;
    }

    /**
     * If new report doesn't match basic condition we just return the function
     * The last valid version of the report will be kept
     */
    if (!this.dprReportBasicCheck(newReport)) {
      return;
    }

    const report = await this.persistentDb.get<OfflineReportWithMetadata>(
      ReportingTableNameEnum.DPR_REPORT,
      +newReport.id,
    );
    if (report === undefined || report === null) {
      await this.persistentDb.put(ReportingTableNameEnum.DPR_REPORT, newReport);
      return;
    }

    const modifiedReport: RecursivePartial<OfflineReportWithMetadata> = {
      ...report,
      ...newReport,
      report: {
        ...(report.report ?? {}),
      },
    };

    await this.persistentDb.put(ReportingTableNameEnum.DPR_REPORT, modifiedReport);
  }

  /**
   * @param allReports ReportMetadata[] - Reports list to be added to dexie, does not contain
   * report detail that we need to load a specific report
   */
  public async bulkPutReportsMetadata(
    allReports: ReportMetadata[],
  ): Promise<void> {
    // Offline db perfs can be bad if there are too many reports, hence only the latest ones are stored
    const monthAgoUtc = dayjs.utc().subtract(1, 'month');
    const reports = allReports.filter(report => dayjs.utc(report.dateOrDatetime).isAfter(monthAgoUtc));

    await Promise.all(
      reports.map(report => this.putReportMetadata(report)),
    );
  }

  /**
   * Report to be added to dexie
   * @param reports ReportState[]
   */
  public async bulkPutReportDetails(reports: ReportState[]): Promise<void> {
    for (const report of reports) {
      await this.putReportDetails(report);
    }
  }

  public async uploadReportOffline(
    reportUploadQuery: ReportUploadQuery,
    { isNewReport = false, forceModifiedOffline = false }: { isNewReport?: boolean; forceModifiedOffline?: boolean } =
      {},
  ): Promise<ReportUploadResult> {
    const modifiedOffline =
      reportUploadQuery.ReportStateInfo.currentHash !== reportUploadQuery.ReportStateInfo.lastUploadHash
      || forceModifiedOffline;
    // If report was modified offline, it was by current user, otherwise it doesn't change
    const modifiedBy = modifiedOffline
      ? this.appInfoService.config.spinergieUser
      : reportUploadQuery.ReportStateInfo.modifiedBy;
    const lastUpdateDatetime = DateHelper.nowIsoString();
    const report: ReportState = {
      ...reportUploadQuery.DailyReport,
      currentHash: reportUploadQuery.ReportStateInfo.currentHash,
      lastUploadHash: reportUploadQuery.ReportStateInfo.lastUploadHash,
      lastSubmittedHash: reportUploadQuery.ReportStateInfo.lastSubmittedHash,
      lastUpload: reportUploadQuery.ReportStateInfo.lastUpload,
      lastUpdate: lastUpdateDatetime,
      approvers: [],
      modifiedBy: modifiedBy,
      statusId: reportUploadQuery.ReportStateInfo.statusId,
      title: reportUploadQuery.ReportStateInfo.title,
      version: reportUploadQuery.ReportStateInfo.version,
      voyageId: reportUploadQuery.DailyReport.voyageId,
    };

    const reportDetailsUploadedOffline = await this.putReportDetails(report, modifiedOffline, isNewReport);

    return {
      success: true,
      reportDistributedTo: null,
      hasConflict: false,
      uploadedReport: reportDetailsUploadedOffline.report,
    };
  }

  public async deleteReport(reportId: number): Promise<void> {
    await this.persistentDb.delete(ReportingTableNameEnum.DPR_REPORT, +reportId);
  }

  public getReportingRefData(): Observable<ReportingRefData> {
    return from(this.persistentDb.get<ReportingRefData>(ReportingTableNameEnum.DPR_REF_DATA, REF_DATA_ID));
  }

  /**
   * @description add / updated locations and capacities of a vessel in indexedDb
   * @param refData
   */
  public async putReportingRefData(
    refData: ReportingRefData,
  ): Promise<void> {
    await this.persistentDb.put(ReportingTableNameEnum.DPR_REF_DATA, {
      id: REF_DATA_ID,
      ...refData,
    });
  }

  /**
   * @param vesselId
   * @returns SelectedVesselConfig
   */
  public async getVesselReportConfig(vesselId: number): Promise<SelectedVesselConfig> {
    return await this.persistentDb.get(ReportingTableNameEnum.SELECTED_VESSEL_CONFIG, +vesselId);
  }

  /**
   * @description add / update report config in indexedDb
   * @param vesselId
   * @param vesselReportConfig
   */
  public async putVesselReportConfig(vesselId: number, vesselReportConfig: SelectedVesselConfig): Promise<void> {
    await this.persistentDb.put(ReportingTableNameEnum.SELECTED_VESSEL_CONFIG, { vesselId, ...vesselReportConfig });
  }

  public async setAutofillAisActivities({
    vesselId,
    date,
  }: Pick<ReportState, 'vesselId' | 'date'>, aisActivities: AisActivity[]): Promise<void> {
    await this.persistentDb.put(ReportingTableNameEnum.DPR_AUTOFILL_AIS_ACTIVITIES, {
      vesselId: vesselId,
      reportDate: dayjs.utc(date).format('YYYY-MM-DD'),
      aisActivitiesGenerationDate: dayjs.utc().valueOf(),
      aisActivities,
    });
  }

  public async getAutofillAisActivities({
    vesselId,
    date,
  }: Pick<ReportState, 'vesselId' | 'date'>): Promise<AisActivity[] | undefined> {
    const result = await this.persistentDb.queryFirst<{ aisActivities?: AisActivity[] }>(
      ReportingTableNameEnum.DPR_AUTOFILL_AIS_ACTIVITIES,
      table =>
        table.where(
          {
            vesselId: vesselId,
            reportDate: dayjs.utc(date).format('YYYY-MM-DD'),
          },
        ),
    );
    return result?.aisActivities;
  }

  /**
   * Returns ais generation date in ts for given report (vesselId/Date)
   * @param param VesselId, Date
   * @returns
   */
  public async getAutofillGenerationDate({
    vesselId,
    date,
  }: Pick<ReportState, 'vesselId' | 'date'>): Promise<number | undefined> {
    const result = await this.persistentDb.queryFirst<{ aisActivitiesGenerationDate?: number | undefined }>(
      ReportingTableNameEnum.DPR_AUTOFILL_AIS_ACTIVITIES,
      table =>
        table.where(
          {
            vesselId: vesselId,
            reportDate: dayjs.utc(date).format('YYYY-MM-DD'),
          },
        ),
    );

    return result?.aisActivitiesGenerationDate;
  }

  public async listVesselIdsWithReportDetails(): Promise<Set<number>> {
    const reports = await this.persistentDb.queryAll<OfflineReportWithMetadata>(
      ReportingTableNameEnum.DPR_REPORT,
    );

    return new Set(
      reports.filter((report: OfflineReportWithMetadata) => this.isReportLoadedOffline(report)).map(report =>
        report.vesselId
      ),
    );
  }
}
